//////////////////////////////////////////////////////////////////////////////
//
//  SIZES
//
//////////////////////////////////////////////////////////////////////////////

$header-height: 56px;


//////////////////////////////////////////////////////////////////////////////
//
//  COLORS
//
//////////////////////////////////////////////////////////////////////////////

$color-primary: #0D283C;
$color-primary-light: #173F5D;
$color-primary-dark: #0B1A24;
$color-secondary: #00A7D6;
$color-secondary-light: #39BBDF;
$color-secondary-dark: #139CC2;
$color-on-primary: #FFFFFF;
$color-onSecondary: #FFFFFF;
$color-app-primary: #FFFFFF;
$color-app-primary-light: #FFFFFF;
$color-app-primary-dark: #E6E6E6;
$color-app-secondary: #424B54;
$color-app-secondary-light: #58616A;
$color-app-secondary-dark: #353C43;
$color-app-on-primary: #424B54;
$color-app-on-secondary: #FFFFFF;
$color-background-primaryTint: #E7ECF0;



//////////////////////////////////////////////////////////////////////////////
//
// FONTS
//
//////////////////////////////////////////////////////////////////////////////

$font-size: 18px;
$md-font-size: 18px;

$line-height: 20px;
$font-family: "Roboto", sans-serif;


//////////////////////////////////////////////////////////////////////////////
//
// BREAKPOINTS
//
//////////////////////////////////////////////////////////////////////////////

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

//////////////////////////////////////////////////////////////////////////////
//
// OTHER
//
//////////////////////////////////////////////////////////////////////////////

$button-grad: linear-gradient(90deg, rgba(164, 149, 101, 1) 0%, rgba(0, 145, 111, 1) 100%);
